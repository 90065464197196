export const Main = () => {

	const onSignIn = ()=>{
		let b : HTMLElement = document.getElementsByClassName('wallet-adapter-button-trigger')[0] as HTMLElement;
		if(b){
           b.click();
		}
        // let button = document.getElementsByClassName("wallet-adapter-button-trigger");
		// button[0].click()
		// console.log(button)
	}

	return (
		<>
			<div className="main__article">
				<h1><div><span>Shaping AI,</span></div><div><span>Forging the Future</span></div></h1>
				<div className="title__row">
					<button  onClick={onSignIn} id="sing__up">Sign Up</button>
					<p>At AI Forge, we stand at the forefront of artificial intelligence development, harnessing the raw power of GPU technology to craft intelligent solutions that drive progress.</p>
				</div>
			</div>
			<div className="article__fixed">

				<div className="solutions__Tailored">
					<h2 data-aos="animation">AI Solutions Tailored for<br/> Excellence</h2>
					<div className="solutions__list">
						<div className="sl__item active" data-aos="animation">
							<div className="sli__body tierA ">
								<div className="sli__details">
									<div className="sliname">Tier</div>
									<div className="slinum">1</div>
								</div>
								<img src="images/tier01.webp" alt="" />
							</div>
						</div>
						<div className="sl__item" data-aos="animation">
							<div className="sli__body tierB">
								<div className="sli__details">
									<div className="sliname">Tier</div>
									<div className="slinum">2</div>
								</div>
								<img src="images/tier02.webp" alt="" />
							</div>
						</div>
						<div className="sl__item" data-aos="animation">
							<div className="sli__body tierC">
								<div className="sli__details">
									<div className="sliname">Tier</div>
									<div className="slinum">3</div>
								</div>
								<img src="images/tier03.webp" alt="" />
							</div>
						</div>
					</div>
					<div className="solutions__Tabs">
						<div className="st__item solTabA active">
							<div className="sti__top">
								<div className="stip__left">
									<h2>Single-GPU<br/> Powerhouse</h2>
									<ul className="stip__list">
										<li><i className="stiop01"></i><strong>1x</strong> NVIDIA GeForce RTX 4090</li>
										<li><i className="stiop02"></i>TRAIN 1 <strong>BOT</strong></li>
										<li><i className="stiop03"></i>AROUND &nbsp<strong>82</strong> TFLOPS</li>
									</ul>
								</div>
								<div className="stip__right">
									<div className="srpr__title">Key features</div>
									<ul>
										<li>This configuration is a dream setup for enthusiasts and professionals alike,
											offering unparalleled single-GPU performance. Ideal for high-end gaming, 3D
											rendering, and AI development tasks that require significant computational power.
										</li>
										<li>Equipped with state-of-the-art cooling solutions to maintain optimal performance
											under load.</li>
										<li>Future-proofed with ample room for upgrades and optimizations.</li>
									</ul>
								</div>
							</div>
							<div className="sti__bottom">
								<div className="sti__title">Tire 01</div>
								<div className="sti__price">$200</div>
							</div>
						</div>
						<div className="st__item solTabB">
							<div className="sti__top">
								<div className="stip__left">
									<h2>Multi-GPU<br/> Behemoth</h2>
									<ul className="stip__list">
										<li><i className="stiop01"></i><strong>6x</strong> NVIDIA GeForce RTX 4090</li>
										<li><i className="stiop02"></i>TRAIN up to <strong>2 BOT</strong></li>
										<li><i className="stiop03"></i>AROUND <strong>492</strong> TFLOPS</li>
									</ul>
								</div>
								<div className="stip__right">
									<div className="srpr__title">Key features</div>
									<ul>
										<li>A powerhouse setup designed for ultra-high performance in professional rendering,
											complex simulations, and deep learning tasks.</li>
										<li>Features advanced cooling systems, including custom liquid cooling loops, to manage
											heat from multiple GPUs.</li>
										<li>Includes a high-end motherboard with ample PCIe slots to support 6 GPUs, alongside a
											robust power supply to ensure stable operations.</li>
									</ul>
								</div>
							</div>
							<div className="sti__bottom">
								<div className="sti__title">Tire 02</div>
								<div className="sti__price">$500</div>
							</div>
						</div>
						<div className="st__item solTabC">
							<div className="sti__top">
								<div className="stip__left">
									<h2>Ultimate<br/> Mega-PC</h2>
									<ul className="stip__list">
										<li><i className="stiop01"></i><strong>12x</strong> NVIDIA GeForce RTX 4090</li>
										<li><i className="stiop02"></i>TRAIN up to<strong>3 BOTs</strong></li>
										<li><i className="stiop03"></i>AROUND <strong>984</strong> TFLOPS</li>
									</ul>
								</div>
								<div className="stip__right">
									<div className="srpr__title">Key features</div>
									<ul>
										<li>The pinnacle of computational performance, this setup is aimed at cutting-edge
											research, massive parallel processing tasks, and high-performance computing
											applications.</li>
										<li>Integrates an enterprise-grade cooling system, possibly involving external cooling
											units, to maintain optimal temperatures across all GPUs.</li>
										<li>Employs a custom-built chassis and motherboard designed specifically to house and
											power 12 high-end GPUs, ensuring maximum performance and reliability.</li>
									</ul>
								</div>
							</div>
							<div className="sti__bottom">
								<div className="sti__title">Tire 03</div>
								<div className="sti__price">$1000</div>
							</div>
						</div>
					</div>
				</div>
				<div className="grid__section">
					<ul className="gridList">
						<li className="gL__item selected bTs01">
							<div className="gLi__body">
								<div className="gLi__title">NFT Valuation and Recommendation</div>
								<div className="gLi__bottom"><span className="gli__status"></span><i className="gli__icon g01"></i></div>
							</div>
						</li>
						<li className="gL__item forge bTs02">
							<div className="gLi__body">
								<div className="gLi__title">Blockchain Analytics</div>
								<div className="gLi__bottom"><span className="gli__status"></span><i className="gli__icon g02"></i></div>
							</div>
						</li>
						<li className="gL__item noactive bTs03">
							<div className="gLi__body">
								<div className="gLi__title">Smart Contract Auditor</div>
								<div className="gLi__bottom"><span className="gli__status"></span><i className="gli__icon g03"></i></div>
							</div>
						</li>
						<li className="gL__item noactive bTs04">
							<div className="gLi__body">
								<div className="gLi__title">Algorithmic Trading</div>
								<div className="gLi__bottom"><span className="gli__status"></span><i className="gli__icon g04"></i></div>
							</div>
						</li>
						<li className="gL__item noactive bTs05">
							<div className="gLi__body">
								<div className="gLi__title">Creative Content Generator</div>
								<div className="gLi__bottom"><span className="gli__status"></span><i className="gli__icon g05"></i></div>
							</div>
						</li>
						<li className="gL__item noactive bTs06">
							<div className="gLi__body">
								<div className="gLi__title">Mining Optimization</div>
								<div className="gLi__bottom"><span className="gli__status"></span><i className="gli__icon g06"></i></div>
							</div>
						</li>
						<li className="gL__item noactive bTs07">
							<div className="gLi__body">
								<div className="gLi__title">Trend Analyzer</div>
								<div className="gLi__bottom"><span className="gli__status"></span><i className="gli__icon g07"></i></div>
							</div>
						</li>
						<li className="gL__item noactive bTs08">
							<div className="gLi__body">
								<div className="gLi__title">Healthcare and Medical Research</div>
								<div className="gLi__bottom"><span className="gli__status"></span><i className="gli__icon g08"></i></div>
							</div>
						</li>
						<li className="gL__item noactive bTs09">
							<div className="gLi__body">
								<div className="gLi__title">Financial Market Analysis</div>
								<div className="gLi__bottom"><span className="gli__status"></span><i className="gli__icon g09"></i></div>
							</div>
						</li>
						<li className="gL__item noactive bTs10">
							<div className="gLi__body">
								<div className="gLi__title">Cybersecurity Defense</div>
								<div className="gLi__bottom"><span className="gli__status"></span><i className="gli__icon g10"></i></div>
							</div>
						</li>
					</ul>
				</div>
				<div className="gridTabs__section">
					<ul className="gTs__list">
						<li className="gTsl__item gTs01 visible">
							<div className="gTsi__body">
								<div className="gTsib__left">
									<div className="gTs__icon"></div>
									<div className="gTsi__title">
										<h3>NFT Valuation and Recommendation</h3>
									</div>
								</div>
								<div className="gTsib__right">
									<p>Analyzes the non-fungible token (NFT) market, using image recognition and market data to
										evaluate and recommend NFT investments. GPUs accelerate the processing of complex
										algorithms that assess rarity, sentiment, and historical sales data.</p>
								</div>
							</div>
						</li>
						<li className="gTsl__item gTs02">
							<div className="gTsi__body">
								<div className="gTsib__left">
									<div className="gTs__icon"></div>
									<div className="gTsi__title">
										<h3>Blockchain Analytics</h3>
									</div>
								</div>
								<div className="gTsib__right">
									<p>Specializes in analyzing blockchain transactions and wallet addresses for patterns,
										anomalies, or suspicious activities. It's crucial for ensuring security and compliance
										in the crypto space, with GPUs enabling the processing of complex blockchain datasets
										rapidly.</p>
								</div>
							</div>
						</li>
						<li className="gTsl__item gTs03">
							<div className="gTsi__body">
								<div className="gTsib__left">
									<div className="gTs__icon"></div>
									<div className="gTsi__title">
										<h3>Smart Contract Auditor</h3>
									</div>
								</div>
								<div className="gTsib__right">
									<p>Automatically reviews and audits smart contract code for vulnerabilities or
										inefficiencies. By simulating millions of transaction scenarios quickly through GPU
										acceleration, it helps secure and optimize contracts on blockchain networks.</p>
								</div>
							</div>
						</li>
						<li className="gTsl__item gTs04">
							<div className="gTsi__body">
								<div className="gTsib__left">
									<div className="gTs__icon"></div>
									<div className="gTsi__title">
										<h3>Algorithmic Trading</h3>
									</div>
								</div>
								<div className="gTsib__right">
									<p>Utilizes machine learning to analyze historical and real-time market data, executing
										trades based on predictive models. Enhanced by GPUs, it can process vast arrays of
										financial indicators and news sources in milliseconds to capitalize on market movements.
									</p>
								</div>
							</div>
						</li>
						<li className="gTsl__item gTs05">
							<div className="gTsi__body">
								<div className="gTsib__left">
									<div className="gTs__icon"></div>
									<div className="gTsi__title">
										<h3>Creative Content Generator</h3>
									</div>
								</div>
								<div className="gTsib__right">
									<p>Utilizing GANs (Generative Adversarial Networks), this bot generates original content,
										including images, videos, and music. The heavy lifting of rendering and processing is
										handled by GPUs to produce high-quality outputs.</p>
								</div>
							</div>
						</li>
						<li className="gTsl__item gTs06">
							<div className="gTsi__body">
								<div className="gTsib__left">
									<div className="gTs__icon"></div>
									<div className="gTsi__title">
										<h3>Mining Optimization</h3>
									</div>
								</div>
								<div className="gTsib__right">
									<p>Optimizes the configuration and operation of GPU-based cryptocurrency mining rigs. It
										continuously analyzes the performance and profitability of different cryptocurrencies,
										adjusting mining strategies in real-time for optimal returns.</p>
								</div>
							</div>
						</li>
						<li className="gTsl__item gTs07">
							<div className="gTsi__body">
								<div className="gTsib__left">
									<div className="gTs__icon"></div>
									<div className="gTsi__title">
										<h3>Trend Analyzer</h3>
									</div>
								</div>
								<div className="gTsib__right">
									<p>Processes vast amounts of data from patents, publications, and market reports to identify
										emerging trends and technologies in the tech industry. This bot helps companies stay
										ahead by leveraging GPUs to conduct deep analysis and prediction models.</p>
								</div>
							</div>
						</li>
						<li className="gTsl__item gTs08">
							<div className="gTsi__body">
								<div className="gTsib__left">
									<div className="gTs__icon"></div>
									<div className="gTsi__title">
										<h3>Healthcare and Medical Research</h3>
									</div>
								</div>
								<div className="gTsib__right">
									<p>A bot designed to process medical images (such as MRIs and X-rays) using deep learning to
										assist in diagnosing diseases or to simulate drug interactions at the molecular level
										for research purposes.</p>
								</div>
							</div>
						</li>
						<li className="gTsl__item gTs09">
							<div className="gTsi__body">
								<div className="gTsib__left">
									<div className="gTs__icon"></div>
									<div className="gTsi__title">
										<h3>Financial Market Analysis</h3>
									</div>
								</div>
								<div className="gTsib__right">
									<p>Using complex algorithms to analyze market data in real-time, this bot provides insights
										and predictions on financial markets. It can process vast datasets quickly to identify
										trends, perform risk analysis, and execute high-frequency trading.</p>
								</div>
							</div>
						</li>
						<li className="gTsl__item gTs10">
							<div className="gTsi__body">
								<div className="gTsib__left">
									<div className="gTs__icon"></div>
									<div className="gTsi__title">
										<h3>Cybersecurity Defense</h3>
									</div>
								</div>
								<div className="gTsib__right">
									<p>By monitoring network traffic and analyzing patterns using machine learning, this bot
										identifies and responds to cybersecurity threats in real-time. GPUs enable the
										processing of large datasets to detect anomalies and potential attacks efficiently.</p>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</>


	)
}

export default Main;