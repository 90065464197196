import { useWallet } from '@solana/wallet-adapter-react';
import {
   WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import Dashboard from './dashboard';
import Main from './main';

export const Page = () => {

   const { publicKey } = useWallet();

   return (
      <>
         <header>
            <div className="header__fixed">
               <div className="logotype"><a href="#">Forge</a></div>
               <div className="nav__row">
                  <a href="#" className="x"></a>
                  <a href="#" className="telegram"></a>
                  <a href="#" className="eagle"></a>
                  {/* <button id="connectWallet">Connect wallet</button> */}
                  <WalletMultiButton className="connect-button">{ !publicKey ? "Sing up" : ""}</WalletMultiButton>
               </div>
            </div>
         </header>
         {
            publicKey &&
            <Dashboard />
         }
         {
            !publicKey &&
            <Main></Main>
         }
      </>
   )
}

export default Page;