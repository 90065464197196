import React, { FC, useState, useEffect, useCallback } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletNotConnectedError, SignerWalletAdapterProps } from '@solana/wallet-adapter-base';
import { Transaction, PublicKey, TransactionInstruction, Connection, SystemProgram, Keypair } from '@solana/web3.js';
import axios from 'axios';
import { config } from '../config';
import {
    createTransferInstruction,
    getAssociatedTokenAddress,
    getAccount,
    createAssociatedTokenAccountInstruction
} from "@solana/spl-token";
import { toHuman } from "../util";
import loadingUrl from "../images/loading.gif";
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('#root');

export const configureAndSendCurrentTransaction = async (
    transaction: Transaction,
    connection: Connection,
    feePayer: PublicKey,
    signTransaction: SignerWalletAdapterProps['signTransaction']
) => {
    const blockHash = await connection.getLatestBlockhash();
    transaction.feePayer = feePayer;
    transaction.recentBlockhash = blockHash.blockhash;
    const signed = await signTransaction(transaction);
    const signature = await connection.sendRawTransaction(signed.serialize());
    await connection.confirmTransaction({
        blockhash: blockHash.blockhash,
        lastValidBlockHeight: blockHash.lastValidBlockHeight,
        signature
    });
    return signature;
};


export const Top: FC = () => {

    const rate = 1;

    const [original, setOriginal] = useState(0);
    const [total, setTotal] = useState(0);
    const [profit, setProfit] = useState(0);
    const [profitDay, setProfitDay] = useState(0);
    const [profitWeek, setProfitWeek] = useState(0);
    const [profitMonth, setProfitMonth] = useState(0);
    const [disableWithdraw, setDisableWithdraw] = useState(false);
    const [disableunstake, setDisableUnstake] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [amount, setAmount] = useState(100);
    const [stakeAmount, setStakeAmount] = useState(100 * 100000000);

    const onAmountChange = (e: any) => {

        setAmount(parseInt(e.target.value));
        setStakeAmount(parseInt(e.target.value) * 100000000);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const openModal = () => {
        setIsOpen(true);
    }

    const { connection } = useConnection();
    const { publicKey, sendTransaction, signTransaction } = useWallet();

    const doStake = (hash: string, stakeAmount: number) => {


        if (!publicKey) throw new WalletNotConnectedError();

        setLoading(true);

        const data = {
            address: publicKey?.toString(),
            amount: stakeAmount,
            hash: hash
        }
        axios.post(`${config.url}/stake/stake`, data).then(() => {
            getData(publicKey?.toString())
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });

    }

    const onWithdraw = () => {

        if (!publicKey) throw new WalletNotConnectedError();

        setDisableWithdraw(true);
        setLoading(true);

        const data = {
            address: publicKey?.toString()
        }
        axios.post(`${config.url}/stake/withdraw`, data).then(() => {
            getData(publicKey?.toString())
            setDisableWithdraw(false);
            setLoading(false);
        }).catch(() => {
            setDisableWithdraw(false);
            setLoading(false);

        });

    }

    const onUnstake = () => {

        if (!publicKey) throw new WalletNotConnectedError();

        setDisableUnstake(true);
        setLoading(true);

        const data = {
            address: publicKey?.toString()
        }
        axios.post(`${config.url}/stake/unstake`, data).then(() => {
            getData(publicKey?.toString())
            setDisableUnstake(false);
            setLoading(false);

        }).catch(() => {
            setDisableUnstake(false);
            setLoading(false);

        });

    }

    const onClick = async () => {

        setIsOpen(false);

        try {

            if (!publicKey || !signTransaction) throw new WalletNotConnectedError();

            const recipientAddress = new PublicKey(config.contract);

            const mintToken = new PublicKey(config.token);

            const transactionInstructions: TransactionInstruction[] = [];
            const associatedTokenFrom = await getAssociatedTokenAddress(
                mintToken,
                publicKey
            );

            const fromAccount = await getAccount(connection, associatedTokenFrom);

            const associatedTokenTo = await getAssociatedTokenAddress(
                mintToken,
                recipientAddress
            );
            if (!(await connection.getAccountInfo(associatedTokenTo))) {
                transactionInstructions.push(
                    createAssociatedTokenAccountInstruction(
                        publicKey,
                        associatedTokenTo,
                        recipientAddress,
                        mintToken
                    )
                );
            }

            transactionInstructions.push(
                createTransferInstruction(
                    fromAccount.address,
                    associatedTokenTo,
                    publicKey,
                    stakeAmount
                )
            );

            const transaction = new Transaction().add(...transactionInstructions);
            const signature = await configureAndSendCurrentTransaction(
                transaction,
                connection,
                publicKey,
                signTransaction
            );

            const blockHash = await connection.getLatestBlockhash();

            const confirm = await connection.confirmTransaction({
                blockhash: blockHash.blockhash,
                lastValidBlockHeight: blockHash.lastValidBlockHeight,
                signature: signature,
            });

            doStake(signature, stakeAmount);

        } catch (err) {
            console.log(err);
        }

    };

    const getData = (address: string) => {

        axios.get(`${config.url}/stake/stakes?address=${address}`)
            .then(response => {
                const data = response.data;
                setOriginal(toHuman(data.original));
                setTotal(toHuman(data.total));
                setProfit(toHuman(data.profit));
                setProfitDay(toHuman(data.profit_day));
                setProfitWeek(toHuman(data.profit_week));
                setProfitMonth(toHuman(data.profit_month));
            });
    }

    useEffect(() => {

        if (publicKey) {
            getData(publicKey.toString());
        }

    }, [publicKey])

    const onClick2 = useCallback(async () => {
        if (!publicKey) throw new WalletNotConnectedError();

        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: new PublicKey(config.contract),
                lamports: 1_000_000,
            })
        );

        const signature = await sendTransaction(transaction, connection);

        await connection.confirmTransaction(signature, 'processed');
    }, [publicKey, sendTransaction, connection]);

    return (
        <div className="db__top">
            <div className="db__item">
                <div className="dbi__inner">
                    <div className="dbi__header">
                        <div className="dbi__label">My Staked Balance</div>
                    </div>
                    <div className="dbi__balance">
                        <div className="dbib__name">$FORGE</div>
                        <div className="dbib__sum"><span className="dbis__top">{original}</span><span className="dbis__bottom">${original * rate}</span></div>
                    </div>
                    <div className="dbi__bottom stackButtons">
                        <button disabled={original !== 0} id="stake" className="check" onClick={onClick2}>stake</button>
                        <button id="unstake" disabled={disableunstake || original === 0} onClick={onUnstake}>unstake</button>
                    </div>
                </div>
            </div>
            <div className="db__item">
                <div className="dbi__inner">
                    <div className="dbi__header">
                        <div className="dbi__label">Total Staked $FORGE</div>
                    </div>
                    <div className="dbi__balance">
                        <div className="dbib__name">$FORGE</div>
                        <div className="dbib__sum"><span className="dbis__top">{total}</span><span className="dbis__bottom">${total * rate}</span></div>
                    </div>
                    <div className="dbi__bottom">
                        <div className="dbi__arrow"></div>
                    </div>
                </div>
            </div>
            <div className="db__item">
                <div className="dbi__inner">
                    <div className="dbi__header">
                        <div className="dbi__label">Profit</div>
                    </div>
                    <div className="dbi__balance">
                        <div className="dbib__sum"><span className="dbis__top">{profit}</span><span className="dbis__bottom">current</span></div>
                        <div className="dbib__sum"><span className="dbis__top">{profitDay}</span><span className="dbis__bottom">24h</span></div>
                        <div className="dbib__sum"><span className="dbis__top">{profitWeek}</span><span className="dbis__bottom">week</span></div>
                        <div className="dbib__sum"><span className="dbis__top">{profitMonth}</span><span className="dbis__bottom">month</span></div>
                    </div>
                    <div className="dbi__bottom">
                        <button disabled={disableWithdraw || original === 0} id="withdraw" onClick={onWithdraw}>Withdraw</button>
                    </div>
                </div>
            </div>
            {
                loading &&
                <div className="loading">
                    <img src={loadingUrl}></img>
                </div>
            }
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                contentLabel="Example Modal"
                onRequestClose={closeModal}
            >
                <div className="modal">
                    <h5>Amount of staking</h5>
                    <input type="number" value={amount} onChange={onAmountChange}></input>
                    <div className='buttons'>
                        <button onClick={onClick} className="tr-ok">ok</button>
                        <button onClick={closeModal} className="tr-cancel">cancel</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Top;