import Top from "./top";
import Leaders from "./leaders";

export const Dashboard = () => {
  return (
    <div className="main__article">
      <h1 className="onerow"><div><span>STAKING &nbsp</span></div><div><span>DASHBOARD</span></div></h1>
      <div className="dashboard__body">
        <Top></Top>
        {/* <Leaders></Leaders> */}
      </div>
    </div>
  );
}

export default Dashboard;