import React, { FC, useMemo, useEffect, useState } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { UnsafeBurnerWalletAdapter } from '@solana/wallet-adapter-wallets';
import {
  WalletModalProvider
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import Page from "./components/page";
import axios from 'axios';
import { config } from './config';
import '@solana/wallet-adapter-react-ui/styles.css';
import './App.css';


export const App: FC = () => {

  const [leaders, setLeaders] = useState([]);

  useEffect(() => {
    axios.get(`${config.url}/stake/top`)
      .then(response => {
        const data = response.data;
        setLeaders(data);
      });
  }, [])

  const network = WalletAdapterNetwork.Mainnet;

  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      new UnsafeBurnerWalletAdapter(),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={'https://cold-dark-field.solana-mainnet.quiknode.pro/ea6e13df57a6fc1d28bf0a038d9788899738e04a/'}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>

          <div className="mainscreen">

            <Page></Page>
          </div>


        </WalletModalProvider>
        <footer>
          <div className="footer__section">
            <div className="footer__toprow">
              <div className="logotype"><a href="#">Forge</a></div>
              <ul className="footer__nav">
                <li><a href="#">About Us</a></li>
                <li><a href="#">Support</a></li>
                <li><a href="#">Services</a></li>
                <li><a href="#">Feedback</a></li>
                <li><a href="#">Contact</a></li>
                <li><a href="#">FAQ</a></li>
              </ul>
            </div>
            <div className="footer__bottomrow">
              <div className="nav__row">
                <a href="#" className="x"></a>
                <a href="#" className="telegram"></a>
                <a href="#" className="eagle"></a>
              </div>
              <div className="design__by"><a href="#">Designed and develeped by ______</a></div>
            </div>
            </div>
        </footer>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;